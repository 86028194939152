import classNames from 'classnames'

import styles from './ContentBlock.module.scss'

export const ContentBlock = ({
  children,
  row,
  column,
  className = '',
  alignStart,
  alignCenter = true,
  alignEnd,
  justifyStart,
  justifyCenter = true,
  justifyEnd,
  flex = true,
  padding,
  margin,
  id,
  title = '',
  ...props
}: {
  children: React.ReactNode
  className?: string
  row?: boolean
  column?: boolean
  alignStart?: boolean
  alignCenter?: boolean
  alignEnd?: boolean
  justifyStart?: boolean
  justifyCenter?: boolean
  justifyEnd?: boolean
  flex?: boolean
  padding?: number
  margin?: number
  id?: string
  style?: React.CSSProperties
  title?: string
}) => {
  return (
    <div
      className={classNames(styles.contentBlock, {
        [styles.row]: row,
        [styles.column]: column,
        [styles.alignStart]: alignStart,
        [styles.alignCenter]: alignCenter,
        [styles.alignEnd]: alignEnd,
        [styles.justifyStart]: justifyStart,
        [styles.justifyCenter]: justifyCenter,
        [styles.justifyEnd]: justifyEnd,
        [styles.flex]: flex,
        [className || '']: className,
      })}
      style={{ padding, margin, ...props.style }}
      {...props}
      id={id}
      title={title}
    >
      {children}
    </div>
  )
}
